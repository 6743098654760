import Button from "@/design-system-v2/Button";
import styled, { css } from "styled-components";
import { layout, space } from "styled-system";
import { TransparentButtonProps } from "./types";

export const TransparentStyledButton = styled(Button)<TransparentButtonProps>`
  ${space}
  ${layout}
  ${({ color }) =>
    color === "light" &&
    css`
      background-color: rgba(255, 255, 255, 0.45);
      color: ${({ theme }) => theme.colors.gray[800]};
      box-shadow: ${({ theme }) => theme.shadows.primary.xs};
      &:hover {
        background-color: rgba(255, 255, 255, 0.65);
      }
      &:active {
        background-color: rgba(255, 255, 255, 0.85);
      }
      &:disabled {
        background-color: rgba(255, 255, 255, 0.45);
        color: ${({ theme }) => theme.colors.gray[400]};
      }
    `}

  ${({ color }) =>
    color === "outline" &&
    css`
      background-color: transparent;
      border: ${({ theme }) => `1px solid ${theme.colors.base.white}`};
      color: ${({ theme }) => theme.colors.base.white};
      box-shadow: ${({ theme }) => theme.shadows.primary.xs};
      &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.45);
      }
      &:disabled {
        border: ${({ theme }) => `1px solid ${theme.colors.gray[400]}`};
        color: ${({ theme }) => theme.colors.gray[400]};
      }
    `}

    ${({ color }) =>
    color === "dark" &&
    css`
      color: ${({ theme }) => theme.colors.base.white};
      background-color: rgba(0, 0, 0, 0.45);
      &:hover {
        background-color: rgba(0, 0, 0, 0.65);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.75);
      }
      &:disabled {
        background-color: rgba(0, 0, 0, 0.45);
        color: rgba(255, 255, 255, 0.3);
      }
    `}
      ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
