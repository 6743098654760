import { FC } from "react";
import { TransparentStyledButton } from "./transparentButton.styles";
import { TransparentButtonProps } from "./types";

const TransparentButton: FC<TransparentButtonProps> = ({ color, ...rest }) => {
  return <TransparentStyledButton color={color} {...rest} />;
};

export default TransparentButton;

// TransparentButton.defaultProps = {
//   color: "default",
// };
